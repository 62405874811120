<script>
import 'lightgallery.js'
import 'lg-zoom.js'

/**
 * @module LightGallery
 */
export default {
  name: 'LightGallery',
  props: {
    item: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => ([])
    }
  },
  // on source change we want to remove the instance
  watch: {
    item: 'cleanup',
    items: 'cleanup'
  },
  // remove the instance before we destroy. Stops memory leaks.
  beforeDestroy () {
    this.cleanup()
  },
  methods: {
    trigger () {
      const dynamicEl = !this.items.length ? [{ src: this.item }] : this.items.map(src => ({ src }))
      window.lightGallery(this.$el, {
        dynamic: true,
        dynamicEl,
        download: false,
        counter: !this.item
      })
    },
    cleanup () {
      const instance = window.lgData[this.$el.getAttribute('lg-uid')]
      if (instance) {
        instance.destroy(true)
      }
    }
  },
  render () {
    return this.$scopedSlots.default({
      trigger: this.trigger
    })
  }
}
</script>

<style lang='scss'>
@import '~utils';

$lg-path-fonts: $assetsPath + "/fonts";
$lg-path-images: $assetsPath + '/images/components/light-gallery';
$zindex-outer: 2250;
$zindex-progressbar: 2283;
$zindex-controls: 2280;
$zindex-toolbar: 2282;
$zindex-subhtml: 2280;
$zindex-thumbnail: 2280;
$zindex-pager: 2280;
$zindex-playbutton: 2280;
$zindex-item: 2260;
$zindex-backdrop: 2240;

@import '~lightgallery.js/src/sass/lightgallery.scss';
</style>
